export const state = () => ({
  self: {}
})

export const mutations = {

  set(state, self) {
    state.self = self;
  },

  delete(state) {
    state.self = {};
  },

}

export const actions = {

  async get({ commit, state }, cookies) {

    // Don't bother to go to the database if you already have the user or you don't have a token.
    if ((state.self && state.self.userId) || cookies.get("token") == null) {
      return state.self;
    }

    let response = await this.$dataService.get("/users/me");

    if (response.data) {
      commit("set", response.data);
      return state.self;
    }

  },

  async signIn(context, payload) {

    // Remove any existing token
    payload.cookies.remove("token");

    let response = await this.$dataService.post("/auths", payload.credentials, { expand: "user" }, { suppressToken: true });
    if (!response.error) {
      actions.setAuthToken(context, { cookies: payload.cookies, token: response.data.token });
      context.commit("set", response.data.user);
    }

    return response;
  },

  setAuthToken({ commit }, payload) {
    var date = new Date();
    date.setHours(date.getHours() + 2160); // 90 days
    payload.cookies.set("token", payload.token, { expires: date, path: "/" });
  },

  async signOut({ commit }, cookies) {

    // If you have a token, delete it on the API side.
    if (cookies.get("token")) {
      await this.$dataService.delete("/auths/me");
    }

    // Remove any existing token
    cookies.remove("token");

    commit("set", {});

    // Redirect to home
    window.location.replace("/");
  },

  async deleteSession({ commit }, cookies) {

    // This is the same as signOut, but doesn't redirect the user to home upon completion.

    // If you have a token, delete it on the API side.
    if (cookies.get("token")) {
      await this.$dataService.delete("/auths/me");
    }

    // Remove any existing token
    cookies.remove("token");

    commit("set", {});
  }


}

