import Vue from 'vue';

import {
  DropdownPlugin,
  ModalPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  SidebarPlugin
} from 'bootstrap-vue';

Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormInputPlugin);
Vue.use(SidebarPlugin);
