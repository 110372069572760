<template>
  <div class="page-wrap d-flex flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center">
          <i class="fas fa-exclamation-triangle fa-3x text-warning"></i>
          <span class="display-1 d-block">{{error.statusCode}}</span>
          <div class="mb-1 sub-text">There was an error processing your request</div>
          <div class="lead">{{error.message}}</div>
          <div class="p-3 error-link" v-if="error.statusCode == 404"><nuxt-link to="/" class="p-4">Back to Home</nuxt-link></div>
          <div class="p-3 error-link" v-if="error.statusCode == 403"><nuxt-link to="/sign-in" class="p-4">Sign In</nuxt-link></div>
          <client-only>
            <div class="p-3 error-link" v-if="error.statusCode != 404 && error.statusCode != 403"><a :href="getPageUrl()" class="p-4">Try Your Request Again</a></div>
          </client-only>
        </div>
      </div>
      <div class="error-reference" v-show="error.reference">Ref: {{error.reference}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["error"],
    methods: {
      getPageUrl() {
        if (process.client) {
          return window.location.href;
        }
      }
    }
  }
</script>

<style>
  body {
    background: #dedede;
  }

  .page-wrap {
    min-height: 100vh;
  }

  .sub-text {
    font-size: 14px;
    font-style: italic;
  }

  .error-link {
    font-size: 26px;
  }

  .error-reference {
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
    text-align: center;
  }
</style>
