export default $axios => context => ({

  // options:
  // suppressToken: true / false. Indicates if the token should be excluded from the request.
  // isFullyQualifiedUrl: true / false. Indicates if the supplied URL is fully qualified and doens't need to have the hostname and base path appended.

  async get(url, params, options) {
    let config = getConfig(params, context.app.$cookies, options);
    let response = { data: null, error: null };
    if (!isFullyQualifiedUrl(url)) {
      url = context.$config.BASE_API_URL + url;
    }
    try {
      response.data = await $axios.$get(url, config);
      return response;
    } catch (error) {
      return parseError(error, context.app.$cookies, context.app.store);
    }
  },

  async post(url, payload, params, options) {
    let config = getConfig(params, context.app.$cookies, options);
    let response = { data: null, error: null };

    if (!isFullyQualifiedUrl(url)) {
      url = context.$config.BASE_API_URL + url;
    }

    try {
      response.data = await $axios.$post(url, payload, config);
      return response;
    } catch (error) {
      return parseError(error, context.app.$cookies, context.app.store);
    }
  },

  async put(url, payload, params, options) {
    let config = getConfig(params, context.app.$cookies, options);
    let response = { data: null, error: null };

    if (!isFullyQualifiedUrl(url)) {
      url = context.$config.BASE_API_URL + url;
    }

    try {
      response.data = await $axios.$put(url, payload, config);
      return response;
    } catch (error) {
      return parseError(error, context.app.$cookies, context.app.store);
    }
  },

  async delete(url, params, options) {
    let config = getConfig(params, context.app.$cookies, options);
    let response = { data: null, error: null };

    if (!isFullyQualifiedUrl(url)) {
      url = context.$config.BASE_API_URL + url;
    }

    try {
      response.data = await $axios.$delete(url, config);
      return response;
    } catch (error) {
      return parseError(error, context.app.$cookies, context.app.store);
    }
  },

  async s3Upload(presignedUrl, file, contentType) {

    let response = { data: null, error: null };

    try {
      response.data = await $axios.$put(presignedUrl, file, {
        headers: {
          "Content-Type": contentType,
        }
      });
      return response;
    } catch (error) {
      return parseError(error, context.app.$cookies, context.app.store);
    }
  },

})

const getConfig = (params, cookies, options) => {

  // Set progress to false to disable the progress bar, for more control we handle it manually in controllers.
  let config = { progress: false };
  config.params = params || {};

  let token = cookies.get("token");

  if (token && (!options || !options.suppressToken)) {
    var headers = {};
    headers.Authorization = "Bearer " + token;
    config.headers = headers;
  }

  return config;
}

const isFullyQualifiedUrl = (url) => {
  if (url.length > 8 && url.substring(0, 8) == "https://") {
    return true;
  }
  return false;
}

const parseError = (error, cookies, store) => {
  let response = { data: null, error: null };

  if (error && error.response && error.response.data) {
    response.error = error.response.data;
  } else {

    let type = "unspecified_error";
    let status = 0;
    if (error && error.response && error.response.status) {
      status = error.response.status;
      switch (Number(error.response.status)) {
        case 400: type = "bad_request";
          break;
        case 401: type = "unauthorized";
          break;
        case 402: type = "transaction_error";
          break;
        case 403: type = "forbidden";
          break;
        case 404: type = "not_found";
          break;
        case 409: type = "conflict";
          break;
        case 422: type = "unprocessable";
          break;
        case 429: type = "too_many_requests";
          break;
        case 500: type = "internal_server_error";
          break;
        case 502: type = "external_server_error";
          break;
        case 504: type = "external_server_timeout";
          break;
      }
    }

    response.error = { type: type, code: "unspecified_error", message: "An error occured attempting to process your request. Please try again. If the problem continues, please contact support for assistance.", reference: "DQTP1Fy", status: status };
  }

  // If a 401 "unauthorized" error, the token is bad. Delete it so the user doesn't get stuck with authorization errors.
  if (response.error && response.error.status == 401) {
    store.commit("self/delete");
    cookies.remove("token");
  }

  return response;

}
