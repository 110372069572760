export const state = () => ({
  design: {}
})

export const mutations = {

  set(state, payload) {
    state.design = { templateId: payload.templateId, isNew: payload.isNew };
  },

  delete(state) {
    state.design = {};
  },

}

export const actions = {

  get({ state }) {
    return state.design;
  },

  set({ commit }, payload) {
    commit("set", payload);
  },

  delete({ commit }) {
    commit("delete", {});
  }

}
