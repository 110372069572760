import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _364f49ef = () => interopDefault(import('..\\pages\\admin\\index.vue' /* webpackChunkName: "pages/admin/index" */))
const _739409f8 = () => interopDefault(import('..\\pages\\board\\index.vue' /* webpackChunkName: "pages/board/index" */))
const _42d25d09 = () => interopDefault(import('..\\pages\\bookmarklet.vue' /* webpackChunkName: "pages/bookmarklet" */))
const _1019d26e = () => interopDefault(import('..\\pages\\compare.vue' /* webpackChunkName: "pages/compare" */))
const _48978f18 = () => interopDefault(import('..\\pages\\connect\\index.vue' /* webpackChunkName: "pages/connect/index" */))
const _12f6e884 = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages/contact" */))
const _0cba5510 = () => interopDefault(import('..\\pages\\get-quozio-pro.vue' /* webpackChunkName: "pages/get-quozio-pro" */))
const _20458182 = () => interopDefault(import('..\\pages\\got-quozio-pro.vue' /* webpackChunkName: "pages/got-quozio-pro" */))
const _03f0aba2 = () => interopDefault(import('..\\pages\\index.aspx.vue' /* webpackChunkName: "pages/index.aspx" */))
const _dde1647a = () => interopDefault(import('..\\pages\\item\\index.vue' /* webpackChunkName: "pages/item/index" */))
const _4f55fc76 = () => interopDefault(import('..\\pages\\join.vue' /* webpackChunkName: "pages/join" */))
const _051d40a2 = () => interopDefault(import('..\\pages\\quote\\index.vue' /* webpackChunkName: "pages/quote/index" */))
const _7587d519 = () => interopDefault(import('..\\pages\\sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _0bf2ba0b = () => interopDefault(import('..\\pages\\user\\index.vue' /* webpackChunkName: "pages/user/index" */))
const _88e04286 = () => interopDefault(import('..\\pages\\admin\\email-templates\\index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _e0ceef24 = () => interopDefault(import('..\\pages\\admin\\featured-items\\index.vue' /* webpackChunkName: "pages/admin/featured-items/index" */))
const _12ec262a = () => interopDefault(import('..\\pages\\admin\\filters\\index.vue' /* webpackChunkName: "pages/admin/filters/index" */))
const _32c3996c = () => interopDefault(import('..\\pages\\admin\\templates\\index.vue' /* webpackChunkName: "pages/admin/templates/index" */))
const _505ca2e4 = () => interopDefault(import('..\\pages\\connect\\create.aspx.vue' /* webpackChunkName: "pages/connect/create.aspx" */))
const _0aff3014 = () => interopDefault(import('..\\pages\\connect\\facebook.vue' /* webpackChunkName: "pages/connect/facebook" */))
const _4838846d = () => interopDefault(import('..\\pages\\connect\\login.aspx.vue' /* webpackChunkName: "pages/connect/login.aspx" */))
const _0a250fe6 = () => interopDefault(import('..\\pages\\connect\\lost.aspx.vue' /* webpackChunkName: "pages/connect/lost.aspx" */))
const _7381c348 = () => interopDefault(import('..\\pages\\quote\\create.vue' /* webpackChunkName: "pages/quote/create" */))
const _734414a1 = () => interopDefault(import('..\\pages\\resources\\how-to.vue' /* webpackChunkName: "pages/resources/how-to" */))
const _58ea3f50 = () => interopDefault(import('..\\pages\\resources\\howTo\\index.vue' /* webpackChunkName: "pages/resources/howTo/index" */))
const _01b07962 = () => interopDefault(import('..\\pages\\resources\\privacy.vue' /* webpackChunkName: "pages/resources/privacy" */))
const _598a51ae = () => interopDefault(import('..\\pages\\resources\\privacy.aspx.vue' /* webpackChunkName: "pages/resources/privacy.aspx" */))
const _d1ff5ce4 = () => interopDefault(import('..\\pages\\resources\\terms.vue' /* webpackChunkName: "pages/resources/terms" */))
const _1e0c584a = () => interopDefault(import('..\\pages\\resources\\terms.aspx.vue' /* webpackChunkName: "pages/resources/terms.aspx" */))
const _177a1537 = () => interopDefault(import('..\\pages\\user\\board-invitation.vue' /* webpackChunkName: "pages/user/board-invitation" */))
const _d76dcc24 = () => interopDefault(import('..\\pages\\user\\designs.vue' /* webpackChunkName: "pages/user/designs" */))
const _298f1780 = () => interopDefault(import('..\\pages\\user\\email-preferences.vue' /* webpackChunkName: "pages/user/email-preferences" */))
const _59eec704 = () => interopDefault(import('..\\pages\\user\\forgot-password.vue' /* webpackChunkName: "pages/user/forgot-password" */))
const _1cba74f0 = () => interopDefault(import('..\\pages\\user\\reset-password.vue' /* webpackChunkName: "pages/user/reset-password" */))
const _7a70ddfa = () => interopDefault(import('..\\pages\\user\\settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _70fe1318 = () => interopDefault(import('..\\pages\\user\\subscription.vue' /* webpackChunkName: "pages/user/subscription" */))
const _57a3ec86 = () => interopDefault(import('..\\pages\\admin\\featured-items\\add.vue' /* webpackChunkName: "pages/admin/featured-items/add" */))
const _0268fe61 = () => interopDefault(import('..\\pages\\admin\\email-templates\\_emailTemplateId\\edit.vue' /* webpackChunkName: "pages/admin/email-templates/_emailTemplateId/edit" */))
const _3d398474 = () => interopDefault(import('..\\pages\\admin\\filters\\_filterId\\edit.vue' /* webpackChunkName: "pages/admin/filters/_filterId/edit" */))
const _c1f4d210 = () => interopDefault(import('..\\pages\\admin\\templates\\_templateId\\edit.vue' /* webpackChunkName: "pages/admin/templates/_templateId/edit" */))
const _3fd3c16c = () => interopDefault(import('..\\pages\\admin\\templates\\_templateId\\preview.vue' /* webpackChunkName: "pages/admin/templates/_templateId/preview" */))
const _60afc75c = () => interopDefault(import('..\\pages\\user\\templates\\_templateId\\edit.vue' /* webpackChunkName: "pages/user/templates/_templateId/edit" */))
const _953e64f4 = () => interopDefault(import('..\\pages\\user\\templates\\_templateId\\edit2.vue' /* webpackChunkName: "pages/user/templates/_templateId/edit2" */))
const _7be80656 = () => interopDefault(import('..\\pages\\board\\_boardId\\index.vue' /* webpackChunkName: "pages/board/_boardId/index" */))
const _27c54861 = () => interopDefault(import('..\\pages\\item\\_boardId\\index.vue' /* webpackChunkName: "pages/item/_boardId/index" */))
const _304ab6ac = () => interopDefault(import('..\\pages\\quote\\_quoteId\\index.vue' /* webpackChunkName: "pages/quote/_quoteId/index" */))
const _4e32a6c4 = () => interopDefault(import('..\\pages\\design\\_templateId\\edit.vue' /* webpackChunkName: "pages/design/_templateId/edit" */))
const _aaf57d3c = () => interopDefault(import('..\\pages\\quote\\_quoteId\\edit.vue' /* webpackChunkName: "pages/quote/_quoteId/edit" */))
const _2312de5e = () => interopDefault(import('..\\pages\\board\\_boardId\\_boardSlug.vue' /* webpackChunkName: "pages/board/_boardId/_boardSlug" */))
const _6d486006 = () => interopDefault(import('..\\pages\\item\\_boardId\\_itemId\\index.vue' /* webpackChunkName: "pages/item/_boardId/_itemId/index" */))
const _470eab56 = () => interopDefault(import('..\\pages\\quote\\_quoteId\\_templateId\\index.vue' /* webpackChunkName: "pages/quote/_quoteId/_templateId/index" */))
const _3cf0e571 = () => interopDefault(import('..\\pages\\item\\_boardId\\_itemId\\_itemSlug.vue' /* webpackChunkName: "pages/item/_boardId/_itemId/_itemSlug" */))
const _8f089a18 = () => interopDefault(import('..\\pages\\quote\\_quoteId\\_templateId\\_quoteSlug.vue' /* webpackChunkName: "pages/quote/_quoteId/_templateId/_quoteSlug" */))
const _e9497194 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _09ba2919 = () => interopDefault(import('..\\pages\\_username.vue' /* webpackChunkName: "pages/_username" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _364f49ef,
    name: "admin"
  }, {
    path: "/board",
    component: _739409f8,
    name: "board"
  }, {
    path: "/bookmarklet",
    component: _42d25d09,
    name: "bookmarklet"
  }, {
    path: "/compare",
    component: _1019d26e,
    name: "compare"
  }, {
    path: "/connect",
    component: _48978f18,
    name: "connect"
  }, {
    path: "/contact",
    component: _12f6e884,
    name: "contact"
  }, {
    path: "/get-quozio-pro",
    component: _0cba5510,
    name: "get-quozio-pro"
  }, {
    path: "/got-quozio-pro",
    component: _20458182,
    name: "got-quozio-pro"
  }, {
    path: "/index.aspx",
    component: _03f0aba2,
    name: "index.aspx"
  }, {
    path: "/item",
    component: _dde1647a,
    name: "item"
  }, {
    path: "/join",
    component: _4f55fc76,
    name: "join"
  }, {
    path: "/quote",
    component: _051d40a2,
    name: "quote"
  }, {
    path: "/sign-in",
    component: _7587d519,
    name: "sign-in"
  }, {
    path: "/user",
    component: _0bf2ba0b,
    name: "user"
  }, {
    path: "/admin/email-templates",
    component: _88e04286,
    name: "admin-email-templates"
  }, {
    path: "/admin/featured-items",
    component: _e0ceef24,
    name: "admin-featured-items"
  }, {
    path: "/admin/filters",
    component: _12ec262a,
    name: "admin-filters"
  }, {
    path: "/admin/templates",
    component: _32c3996c,
    name: "admin-templates"
  }, {
    path: "/connect/create.aspx",
    component: _505ca2e4,
    name: "connect-create.aspx"
  }, {
    path: "/connect/facebook",
    component: _0aff3014,
    name: "connect-facebook"
  }, {
    path: "/connect/login.aspx",
    component: _4838846d,
    name: "connect-login.aspx"
  }, {
    path: "/connect/lost.aspx",
    component: _0a250fe6,
    name: "connect-lost.aspx"
  }, {
    path: "/quote/create",
    component: _7381c348,
    name: "quote-create"
  }, {
    path: "/resources/how-to",
    component: _734414a1,
    name: "resources-how-to"
  }, {
    path: "/resources/howTo",
    component: _58ea3f50,
    name: "resources-howTo"
  }, {
    path: "/resources/privacy",
    component: _01b07962,
    name: "resources-privacy"
  }, {
    path: "/resources/privacy.aspx",
    component: _598a51ae,
    name: "resources-privacy.aspx"
  }, {
    path: "/resources/terms",
    component: _d1ff5ce4,
    name: "resources-terms"
  }, {
    path: "/resources/terms.aspx",
    component: _1e0c584a,
    name: "resources-terms.aspx"
  }, {
    path: "/user/board-invitation",
    component: _177a1537,
    name: "user-board-invitation"
  }, {
    path: "/user/designs",
    component: _d76dcc24,
    name: "user-designs"
  }, {
    path: "/user/email-preferences",
    component: _298f1780,
    name: "user-email-preferences"
  }, {
    path: "/user/forgot-password",
    component: _59eec704,
    name: "user-forgot-password"
  }, {
    path: "/user/reset-password",
    component: _1cba74f0,
    name: "user-reset-password"
  }, {
    path: "/user/settings",
    component: _7a70ddfa,
    name: "user-settings"
  }, {
    path: "/user/subscription",
    component: _70fe1318,
    name: "user-subscription"
  }, {
    path: "/admin/featured-items/add",
    component: _57a3ec86,
    name: "admin-featured-items-add"
  }, {
    path: "/admin/email-templates/:emailTemplateId?/edit",
    component: _0268fe61,
    name: "admin-email-templates-emailTemplateId-edit"
  }, {
    path: "/admin/filters/:filterId/edit",
    component: _3d398474,
    name: "admin-filters-filterId-edit"
  }, {
    path: "/admin/templates/:templateId/edit",
    component: _c1f4d210,
    name: "admin-templates-templateId-edit"
  }, {
    path: "/admin/templates/:templateId/preview",
    component: _3fd3c16c,
    name: "admin-templates-templateId-preview"
  }, {
    path: "/user/templates/:templateId?/edit",
    component: _60afc75c,
    name: "user-templates-templateId-edit"
  }, {
    path: "/user/templates/:templateId?/edit2",
    component: _953e64f4,
    name: "user-templates-templateId-edit2"
  }, {
    path: "/board/:boardId",
    component: _7be80656,
    name: "board-boardId"
  }, {
    path: "/item/:boardId",
    component: _27c54861,
    name: "item-boardId"
  }, {
    path: "/quote/:quoteId",
    component: _304ab6ac,
    name: "quote-quoteId"
  }, {
    path: "/design/:templateId?/edit",
    component: _4e32a6c4,
    name: "design-templateId-edit"
  }, {
    path: "/quote/:quoteId/edit",
    component: _aaf57d3c,
    name: "quote-quoteId-edit"
  }, {
    path: "/board/:boardId/:boardSlug",
    component: _2312de5e,
    name: "board-boardId-boardSlug"
  }, {
    path: "/item/:boardId/:itemId",
    component: _6d486006,
    name: "item-boardId-itemId"
  }, {
    path: "/quote/:quoteId/:templateId",
    component: _470eab56,
    name: "quote-quoteId-templateId"
  }, {
    path: "/item/:boardId/:itemId/:itemSlug",
    component: _3cf0e571,
    name: "item-boardId-itemId-itemSlug"
  }, {
    path: "/quote/:quoteId/:templateId/:quoteSlug",
    component: _8f089a18,
    name: "quote-quoteId-templateId-quoteSlug"
  }, {
    path: "/",
    component: _e9497194,
    name: "index"
  }, {
    path: "/:username",
    component: _09ba2919,
    name: "username"
  }, {
    path: "/admin/email-templates/add",
    component: _0268fe61,
    name: "admin-email-templates-add"
  }, {
    path: "/admin/templates/add",
    component: _c1f4d210,
    name: "admin-templates-add"
  }, {
    path: "/admin/filters/add",
    component: _3d398474,
    name: "admin-filters-add"
  }, {
    path: "/design/new",
    component: _4e32a6c4,
    name: "design-new"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
