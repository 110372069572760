import https from 'https';

export default function ({ $axios, store, $config }) {

  const agent = new https.Agent({
    rejectUnauthorized: false
  });

  $axios.onRequest(config => {
    if ($config.IGNORE_SSL_ERRORS) {
      config.httpsAgent = agent;
    }
  });

}
