import api from "~/api/api";

// Dependency injection pattern as described here. SSR friendly.
// https://blog.lichter.io/posts/nuxt-api-call-organization-and-decoupling/
// https://codesandbox.io/s/github/manniL/nuxt-decouple-and-organize-api-calls?file=/api/repository.js

// To get data within asyncData or fetch:
// await context.app.$dataService.get(url);

// To get data within methods, created, etc.:
// await this.$dataService.get(url);

export default (context, inject) => {
  const repo = api(context.$axios);
  inject("dataService", repo(context));
}
