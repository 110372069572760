export const state = () => ({
  templates: []
})

export const mutations = {

  setList(state, templates) {
    state.templates = templates;
  },

  deleteList(state) {
    state.templates = [];
  },

}

export const actions = {

  async getList({ commit, state }, params) {
    // Since they don't change often, only get the templates if we don't already have them.
    if (state.templates && state.templates.length) {
      return state.templates;
    } else {
      let response = await this.$dataService.get("/templates", params);
      if (response.data) {
        commit("setList", response.data.data);
      }
    }
  },

  deleteList() {
    commit("deleteList");
  },

}

